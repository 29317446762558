export class Grilla {
  id: number;

  grillaId: string;

  nombre: string;

  constructor(obj: any) {
    this.id = obj.id;
    this.nombre = obj.titulo;
    this.grillaId = obj.grillaId;
  }
}
