import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Modulo } from '../../models/modulo.model';
import { Grilla } from '../../models/grilla.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(
    public http: ApiService
  ) { }

  /**
   * Gets configuration item's value.
   */
  public getItemValue(item: string) {
    const params = new HttpParams();
    return this.http.get(`configuracion/${item}`, params);
  }

  /**
   * Gets the full list of modules
   * @returns Observable with the list of available modules
   */
  getModules() {
    return this.http
      .get('modulos-grillas/modulos')
      .pipe(map((result: any) => (result?.modulos && result?.modulos.map((m) => new Modulo(m))) || []));
  }

  /**
     * Gets the list of grids of the selected module
     * @param moduleId module id
     * @returns Observable with the list of available grids of an specific module.
     */
  getGrids(moduleId: string) {
    return this.http
      .get(`modulos-grillas/grillas/${moduleId}`)
      .pipe(map((result: any) => result?.grillas?.map((g) => new Grilla(g)) || []));
  }
}
